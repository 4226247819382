/* .footer {
    background-color: rebeccapurple;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    height: 50px;
} */

.footer {
    display: flex;
    justify-content: center;
    background-color: red;
}