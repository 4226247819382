body {
    background-color: rebeccapurple;
    background: linear-gradient(360deg, #09203f, #537895);
    color: blanchedalmond;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 1rem;
    margin: 0 auto;
}

main {
    flex-grow: 1;
    padding: 0 5rem 0 5rem;
}